/* eslint-disable max-len */
const S1 =
  '1992: Metro Vision Statement Principles and Policies: Board of Directors accepts principles and policies developed by task force charged with preparing a new plan. These principles and policies expressed the fundamental elements of a vision for the region.'
const S2 =
  '1995: Vision Framework: The Vision Framework was a major milestone in the development of Metro Vision 2020. The Framework examined alternative growth scenarios as the region expected to add nearly 1 million people between 1990 and 2020.'
const S3 =
  '1997: Metro Vision 2020: After seven years of working with the public and stakeholders around the region the DRCOG Board adopted Metro Vision 2020. Metro Vision 2020 integrated multiple plans into a single vision for the future and was a significant milestone in defining and acheiving consensus on key regional issues.'
const S4 =
  '2000: Mile High Compact: The Compact was a landmark intergovernmental agreement to voluntarily and collaboratively manage the nature and location of growth in the Denver region. The Mile High Compact is viewed as an innovative model for regional cooperation.'
const S5 =
  '2005: Metro Vision 2030: The first major update to Metro Vision extended the planning horizon to the year 2030. Numerous work groups refined core components of the first Metro Vision plan and updated recommended implementation activities.'
const S6 =
  '2007: Metro Vision 2035: The Metro Vision 2035 plan built on previous plans and extended the planning horizon to 2035. Metro Vision 2035 emphasized the importance development patterns and design features that meet the needs of residents as they age. The updated plan established a new target for open space protection.'
const S7 =
  '2011: Metro Vision 2035 – Update: The DRCOG Board led a two-year effort to further incorporate sustainability and measurable goals and targets into the plan. This major update to the Metro Vision relied on significant contributions from stakeholders as the Board defined what a sustainble region would look like, and how the region will know if we’re becoming more sustainable.'
const S8 =
  '2017: Metro Vision (current): Metro Vision’s core values have been carried forward in the most recent update. The plan continues the region’s emphasis measuring progress and considers the region’s social and economic health alongside its physical development.'

/* eslint-enable max-len */

module.exports = [
  {
    date: '1992',
    picture: '/whathistory/MV-Principles-and-Policies.png',
    title: 'Metro Vision Statement Principles and Policies',
    link:
      'https://drcog.org/sites/drcog/files/resources/1992_DRCOG_Metro_Vision_Principles_and_Policies.pdf',
    content: S1,
  },
  {
    date: '1995',
    picture: '/whathistory/Metro-Vision-Framework.png',
    title: 'Vision Framework',
    link:
      'https://drcog.org/sites/drcog/files/resources/1995%20MV%20Framework%201b.pdf',
    content: S2,
  },
  {
    date: '1997',
    picture: '/whathistory/Metro-Vision-2020.png',
    title: 'Metro Vision 2020',
    link:
      'https://drcog.org/sites/drcog/files/resources/2020_Metro_Vision_Plan-1.pdf',
    content: S3,
  },
  {
    date: '2000',
    picture: '/whathistory/Mile-High-Compact.png',
    title: 'Mile High Compact',
    link:
      'https://drcog.org/sites/drcog/files/resources/MHC%20signature%20page%208.5%20x%2011.pdf',
    content: S4,
  },
  {
    date: '2005',
    picture: '/whathistory/Metro-Vision-2030.png',
    title: 'Metro Vision 2030',
    link:
      'https://drcog.org/sites/drcog/files/resources/WebMetro%20Vision%202030%20plan%20final%201-05.pdf',
    content: S5,
  },
  {
    date: '2007',
    picture: '/whathistory/Metro-Vision-2035.png',
    title: 'Metro Vision 2035',
    link:
      'https://drcog.org/sites/drcog/files/resources/MetroVision2035FinalPlanIntro-Ch%202%20%281%29.pdf',
    content: S6,
  },
  {
    date: '2011',
    picture: '/whathistory/Metro-Vision-2035-update.png',
    title: 'Metro Vision 2035 - Update',
    link:
      'https://drcog.org/sites/drcog/files/resources/2011%20MV%202035%20Plan%20for%20Web5-12-11.pdf',
    content: S7,
  },
  {
    date: '2017',
    picture: '/whathistory/Metro-Vision.png',
    title: 'Metro Vision',
    link:
      'https://drcog.org/sites/default/files/resources/Amended_Metro_Vision_May_15_2019.pdf',
    content: S8,
  },
]
