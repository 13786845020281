import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

// icons
import { MdArrowForward, MdArrowBack } from 'react-icons/md'
import constants from '../constants'

// this handles the rendering part of the buttons that appear on either side of
// the timeline.

/**
 * These are the static styles for the buttons on either side of the timeline.
 *
 * @param {styles} styles The user-definded styles/the default styles
 * @param {boolean} active Hacky crap to get svg filling color right
 * @return {object} An object containing styles for the buttons
 * link: styles defined for the link elements i.e. the href tag.
 * icon: styles defined for the icon that appears on the button.
 * inactive: styles defined for when the icons are inactive.
 */
const buttonStyles = {
  link: ({ outline }) => ({
    position: 'absolute',
    top: '49px',
    bottom: 'auto',
    transform: 'translateY(-50%)',
    height: 34,
    width: 34,
    borderRadius: '50%',
    border: `2px solid ${outline}`,
    overflow: 'hidden',
    textIndent: '100%',
    whiteSpace: 'nowrap',
    transition: 'border-color 0.3s',
  }),
  icon: (styles, active) => ({
    position: 'absolute',
    left: 0,
    top: '50%',
    bottom: 'auto',
    transform: 'translateY(-50%)',
    height: 20,
    width: 29,
    overflow: 'hidden',
    textIndent: '100%',
    whiteSpace: 'nowrap',
    fill: active ? styles.foreground : '#d7d9d8',
  }),
  inactive: styles => ({
    color: '#d7d9d8',
    border: '2px solid #d7d9d8',
    cursor: 'not-allowed',
    ':hover': {
      border: '2px solid #d7d9d8',
    },
  }),
  active: styles => ({
    cursor: 'pointer',
    ':hover': {
      border: `2px solid ${styles.foreground}`,
      color: styles.foreground,
    },
  }),
}

/**
 * Markup for both the buttons (that translate the timeline left or right).
 *
 * @param  {object} props The info provided by the parent
 * @return {StatelessFunctionalReactComponent} The Markup info for both the buttons
 */
const TimelineButtons = props => {
  const buttonBackEnabled = Math.round(props.position) < 0
  const buttonForwardEnabled =
    Math.round(props.position) > Math.round(props.maxPosition)

  return (
    <ul className="buttons">
      <li
        className={`button-back ${buttonBackEnabled ? 'enabled' : 'disabled'}`}
        key={constants.LEFT}
        onClick={() => props.updateSlide(constants.LEFT)}
        style={[
          buttonStyles.link(props.styles),
          buttonBackEnabled
            ? buttonStyles.active(props.styles)
            : buttonStyles.inactive(props.styles),
          { [constants.LEFT]: 0 },
        ]}
      >
        <MdArrowBack
          style={buttonStyles.icon(props.styles, buttonBackEnabled)}
        />
      </li>
      <li
        className={`button-forward ${
          buttonForwardEnabled ? 'enabled' : 'disabled'
        }`}
        key={constants.RIGHT}
        onClick={() => props.updateSlide(constants.RIGHT)}
        style={[
          buttonStyles.link(props.styles),
          buttonForwardEnabled
            ? buttonStyles.active(props.styles)
            : buttonStyles.inactive(props.styles),
          { [constants.RIGHT]: 0 },
        ]}
      >
        <MdArrowForward
          style={buttonStyles.icon(props.styles, buttonForwardEnabled)}
        />
      </li>
    </ul>
  )
}

// Wrapping the buttons with Radium (so we get all the styling goodness)
export default Radium(TimelineButtons)
