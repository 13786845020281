import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import TimelineContainer from '../components/Timeline/components/TimelineContainer'
import MVInfo from '../components/Timeline/resources/content'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import Container from '../components/Container'
import Highlight from '../components/Highlight'
import Section from '../components/Section'
import SectionWithAside from '../components/SectionWithAside'

export const AboutPageTemplate = ({
  bannerimage,
  title,
  theme,
  sectionwithaside,
  secondsection,
  timeline,
  thirdsection,
  mediasection,
  fourthsection,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="about" label="About" />
    <Container className="pb-10 md:pb-0 pt-12 sm:pt-16 md:pt-20 flex flex-col lg:flex-row">
      <SectionWithAside content={sectionwithaside} />
    </Container>
    <Container className="flex pt-8 sm:pt-10 md:pt-12">
      <Section titleStyle="text-center text-pink" content={secondsection} />
    </Container>
    <Container className="pt-8 sm:pt-10 md:pt-12">
      <TimelineContainer content={timeline} />
    </Container>
    <Container className="flex py-10 sm:py-12 md:py-16">
      <Section titleStyle="text-center text-pink" content={thirdsection} />
    </Container>
    <Container
      className="pt-4 pb-12 sm:pb-16"
      outerClassName="bg-teal text-white"
    >
      <Highlight highlight={mediasection} />
    </Container>
    <Container className="flex py-10 sm:py-12 md:py-16">
      <Section content={fourthsection} />
    </Container>
  </>
)

AboutPageTemplate.propTypes = {
  bannerimage: PropTypes.object,
  title: PropTypes.string,
  theme: PropTypes.string,
  sectionwithaside: PropTypes.object,
  secondsection: PropTypes.object,
  timeline: PropTypes.array,
  thirdsection: PropTypes.object,
  mediasection: PropTypes.object,
  fourthsection: PropTypes.object,
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <AboutPageTemplate
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        sectionwithaside={frontmatter.sectionwithaside}
        secondsection={frontmatter.secondsection}
        timeline={frontmatter.timeline}
        thirdsection={frontmatter.thirdsection}
        mediasection={frontmatter.mediasection}
        fourthsection={frontmatter.fourthsection}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPageTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionwithaside {
          title
          body
          asidetitle
          asidebody
        }
        secondsection {
          title
          body
        }
        thirdsection {
          title
          body
        }
        timeline {
          year
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          link
          content
        }
        mediasection {
          title
          body
          highlightsection {
            type
            videoid
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slide {
              caption
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        fourthsection {
          title
          body
        }
      }
    }
  }
`
