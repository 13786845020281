/* eslint-disable camelcase */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Image from '../../Image'

import Timeline from './Timeline'

const TimelineContainer = ({ content }) => {
  const data = content.map((timelineitem, index) => ({
    date: timelineitem.year,
    component: (
      <div
        className="container"
        style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}
        key={index}
      >
        <div className="flex container">
          <div className="hidden lg:block">
            {timelineitem.link ? (
              <a href={timelineitem.link} download>
                <div className="w-56">
                  <Image imageInfo={timelineitem.image} />
                </div>
              </a>
            ) : (
              <div className="w-56">
                <Image imageInfo={timelineitem.image} />
              </div>
            )}
          </div>
          <div>
            {timelineitem.link ? (
              <a href={timelineitem.link} download>
                <h3 className="text-orange text-center text-xl">
                  {timelineitem.title}
                </h3>
              </a>
            ) : (
              <h3 className="text-orange text-center text-lg">
                {timelineitem.title}
              </h3>
            )}
            <p className="font-body text-black-darker">
              {timelineitem.content}
            </p>
          </div>
        </div>
      </div>
    ),
  }))
  return <TimelineContent content={data} />
}

export default TimelineContainer

class TimelineContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      previous: 0,
      showConfigurator: false,

      // timelineConfig
      minEventPadding: 2,
      maxEventPadding: 60,
      linePadding: 100,
      labelWidth: 50,
      fillingMotionStiffness: 150,
      fillingMotionDamping: 25,
      slidingMotionStiffness: 150,
      slidingMotionDamping: 25,
      stylesBackground: '#ffffff',
      stylesForeground: '#F58220',
      stylesOutline: '#009DDC',
      isTouchEnabled: true,
      isKeyboardEnabled: true,
      isOpenEnding: true,
      isOpenBeginning: true,
    }
  }

  UNSAFE_componentWillMount() {
    this.dates = this.props.content.map(entry => entry.date)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.dates = nextProps.content.map(entry => entry.date)
  }

  render() {
    const { state } = this

    const views = this.props.content.map((entry, index) => (
      <div className="container" key={index}>
        {entry.component}
      </div>
    ))

    return (
      <div>
        <div style={{ width: '80%', height: '100px', margin: '0 auto' }}>
          <Timeline
            fillingMotion={{
              stiffness: state.fillingMotionStiffness,
              damping: state.fillingMotionDamping,
            }}
            index={this.state.value}
            indexClick={index => {
              this.setState(prevState => ({
                value: index,
                previous: prevState.value,
              }))
            }}
            isKeyboardEnabled={state.isKeyboardEnabled}
            isTouchEnabled={state.isTouchEnabled}
            labelWidth={state.labelWidth}
            linePadding={state.linePadding}
            maxEventPadding={state.maxEventPadding}
            minEventPadding={state.minEventPadding}
            slidingMotion={{
              stiffness: state.slidingMotionStiffness,
              damping: state.slidingMotionDamping,
            }}
            styles={{
              background: state.stylesBackground,
              foreground: state.stylesForeground,
              outline: state.stylesOutline,
            }}
            values={this.dates}
            isOpenEnding={state.isOpenEnding}
            isOpenBeginning={state.isOpenBeginning}
          />
        </div>
        <div className="text-center">
          <SwipeableViews
            index={this.state.value}
            onChangeIndex={(value, previous) => {
              this.setState({ value, previous })
            }}
            resistance
          >
            {views}
          </SwipeableViews>
        </div>
      </div>
    )
  }
}
